<template>
  <div>
    <div v-if="claim" class="mt-20 px-30 py-20">
      <a-descriptions :column="{ xxl: 4, xl: 3, lg: 2, xs: 1 }" style="text-align: left">
        <template #title>
          <h6>{{ $t('title.claimDetail') }}</h6>
        </template>
        <a-descriptions-item :label="$t('label.claimId')">
          {{ claim.id }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('label.certificateNo')">
          {{ claim.certificate ? claim.certificate.no : "" }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('label.claimTo')">
          {{ claim.certificatemember ? claim.certificatemember.firstname : "" }}
          {{ claim.certificatemember ? claim.certificatemember.lastname : "" }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('form.claimPurpose')">
          {{ claim.type }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('label.amount')">
          {{ formatMoney(claim.amount) }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('label.status')">
          <a-tag :color="statusTagColor(claim.status)">
            {{ claim.status }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('label.claimDate')">
          {{ formatDate(claim.reqtime) }}
        </a-descriptions-item>
      </a-descriptions>
      <h6>{{ $t("form.document") }}:</h6>
      <WidgetMultiPreviewPhoto :width="100" :height="150" :photo="claim.photo" />
      <div class="mt-30">
        <CardClaimHistory :claims="claimHistory" />
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
import claimService from "../../services/claim.service";
import { message } from "ant-design-vue";
import { formatMoney, formatDate, statusTagColor } from "../../utils/helper";
import Loading from "../../components/Loading/Loading.vue";
import WidgetMultiPreviewPhoto from "../../components/Widgets/WidgetMultiPreviewPhoto.vue";
import CardClaimHistory from '../../components/Cards/CardClaimHistory.vue';

export default {
  name: "ClaimDetail",
  components: {
    Loading,
    WidgetMultiPreviewPhoto,
    CardClaimHistory
  },
  data() {
    return {
      claimId: "",
      claim: null,
      claimHistory: [],
      claimData: {
        certificateNo: "",
        memberId: "",
      },
    };
  },
  created() {
    this.checkRouteId();
  },
  watch: {
    '$route.params.id': 'checkRouteId'
  },
  methods: {
    async checkRouteId() {
      this.claimId = this.$route.params.id;
      if (this.claimId) {
        await this.getClaimById();
      }
    },
    async getClaimById() {
      try {
        const res = await claimService.getClaimById(this.claimId);
        this.claim = res.data;
        this.claimData.certificateNo = res.data.certificate_id;
        this.claimData.memberId = res.data.certificatemember_id;
        this.getClaimHistory();
      } catch (err) {
        message.error(err.message);
      }
    },
    async getClaimHistory() {
      try {
        const res = await claimService.getClaimHistory(this.claimData);
        this.claimHistory = res.data;
      } catch (err) {
        message.error(err.message);
      }
    },
    formatMoney,
    formatDate,
    statusTagColor,
  },
};
</script>