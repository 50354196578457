<template>
  <a-card
    :bordered="true"
    style="border-radius: 0;"
  >
    <template #title>
      <h6>{{ $t('title.claimHistory') }}</h6>
    </template>
    <a-table
      :columns="columns"
      :data-source="claims"
      :pagination="false"
      :row-key="(record) => record.id"
    >
      <template slot="reqtime" slot-scope="reqtime">
        <span>{{ formatDate(reqtime) }}</span>
      </template>
      <template slot="amount" slot-scope="amount">
        <span>{{ formatMoney(amount) }}</span>
      </template>
      <template slot="claimTo" slot-scope="claimTo">
        <span>{{ claimTo.firstname }} {{ claimTo.lastname }}</span>
      </template>
      <template slot="status" slot-scope="status">
        <a-tag :color="statusTagColor(status)">{{ status }}</a-tag>
      </template>
      <template slot="action" slot-scope="text, record">
        <span>
          <router-link :to="onClaimedHistorySelected(record.id)">{{ $t("action.viewButton") }}</router-link>
        </span>
      </template>
    </a-table>
  </a-card>
</template>
  
  <script>
import { formatDate, formatMoney, statusTagColor } from "../../utils/helper";
export default {
  name: "CardClaimHistory",
  props: {
    claims: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t('label.claimId'),
          dataIndex: "id",
          key: "id",
          width: 110,
        },
        {
          title: this.$t('label.claimDate'),
          dataIndex: "reqtime",
          key: "reqtime",
          width: 130,
          scopedSlots: { customRender: "reqtime" },
        },
        {
          title: this.$t("form.claimPurpose"),
          dataIndex: "type",
          key: "type",
          width: 130,
        },
        {
          title: this.$t('label.amount'),
          dataIndex: "amount",
          key: "amount",
          width: 150,
          scopedSlots: { customRender: "amount" },
        },
        {
          title: this.$t('label.claimTo'),
          dataIndex: "certificatemember",
          key: "claimTo",
          width: 300,
          scopedSlots: { customRender: "claimTo" },
        },
        {
          title: this.$t('label.status'),
          dataIndex: "status",
          key: "status",
          width: 100,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Action",
          dataIndex: "action",
          width: 130,
          scopedSlots: { customRender: "action" },
        },
      ];
    },
  },
  methods: {
    formatDate,
    formatMoney,
    statusTagColor,
    onClaimedHistorySelected(id) {
      return `/claim/detail/${id}`
    },
  },
};
</script>
  