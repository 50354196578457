<template>
  <div v-if="photo" class="clearfix">
    <a-row :gutter="24">
      <a-col
        v-for="(item, index) in photo"
        :key="index"
        :xs="8"
        :md="4"
        :xl="3"
        :xxl="2"
      >
        <img
          @click="getPreviewPhoto(item)"
          alt=""
          :style="{
            width: width ? `${width}px` : '80%',
            height: height ? `${height}px` : '150px',
            marginTop: '10px',
          }"
          :src="parseImageUrl(item)"
        />
      </a-col>
    </a-row>
    <a-modal
      :visible="previewVisible"
      :footer="null"
      :width="600"
      @cancel="handleCancel"
    >
      <img alt="" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
  <script>
import { parseImageUrl } from "../../utils/helper";
export default {
  name: "WidgetMultiPreviewPhoto",
  props: {
    photo: {
      type: Array,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      previewVisible: false,
      previewImage: "",
    };
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
      this.previewImage = ";";
    },
    parseImageUrl,
    async getPreviewPhoto(photo) {
      try {
        if (photo) {
          this.previewImage = await parseImageUrl(photo);
          this.previewVisible = true;
        }
        return null;
      } catch (error) {
        console.error("Error parsing photo:", error);
        return require(`@/assets/images/no-image.png`);
      }
    },
  },
};
</script>
  